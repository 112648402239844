import React, { useMemo } from 'react';
import { LineChart, ReferenceArea, ReferenceLine, ReferenceDot, Label, Legend } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { fixedNum, get, hashCode } from '@dex/bubl-helpers';

const ChartDehydration: React.FC<ChartDehydrationProps> = (props) => {

    let { data, duration, targetTime, form, criticalLow, criticalHigh, intersectLow, intersectHigh, fluidIntakeRateHigh, fluidIntakeRateLow } = props;

    let hours: any = [];
    let interval = 0;

    let maxHours = duration;

    if (data && data[data.length - 1].duration > maxHours) maxHours = Math.ceil(data[data.length - 1].duration);

    if (maxHours > 12) interval = 1;
    if (maxHours > 24) interval = fixedNum(maxHours / 12, 0);

    for (let i = 0; i <= maxHours; i++) {
        hours.push(i);
    }

    const Axis = new ChartAxis();

    Axis.grid = {
        vertical: true,
    };

    Axis.x = {
        key: "duration",
        index: 6,
        label: "Activity Duration (hrs)",
        props: {
            // dataKey: "duration",
            // key: "duration",
            interval: interval,
            domain: [0, hours[hours.length - 1]],
            type: "number",
            ticks: hours
        }
    };

    Axis.y.push({
        axis: true,
        pos: "left",
        index: 6,
        key: "low",
        axisLabel: "Weight Loss %",
        props: {
            interval: 0,
            domain: [0, 6],
            type: "number",
            ticks: [0, 1, 2, 3, 4, 5, 6],
        }
    });

    Axis.y.push({
        line: true,
        pos: "left",
        index: 2,
        key: "low",
        label: "Fluid Intake " + fluidIntakeRateLow + " ml/hr",
        props: {
            unit: "%",
            dot: { fill: ChartProps.indexColor(2) },
            connectNulls: true,
        }
    });

    Axis.y.push({
        axis: false,
        line: true,
        pos: "left",
        index: 1,
        key: "high",
        label: "Fluid Intake " + fluidIntakeRateHigh + " ml/hr",
        props: {
            unit: "%",
            dot: { fill: ChartProps.indexColor(1) },
            connectNulls: true,
        }
    });

    const targetTimeLabel = (props: any) => {

        const { viewBox, intersect } = props;

        return (
            <g transform={`translate(${viewBox.x + 15},${viewBox.y})`}>
                <text x={0} y={0} dy={16} fill={ChartProps.indexColor(6)}>
                    <tspan textAnchor="left" x="0">
                        {/* Target Time: {targetTime} hrs
                    </tspan>
                    <tspan textAnchor="left" x="0" dy="20"> */}
                        Wt Loss: {fixedNum(intersect, 1)}%
                    </tspan>
                </text>
            </g>
        );

    };

    return useMemo(() => (

        <Chart key={Axis.x?.key}>

            <LineChart
                {...ChartProps.LineChart()}
                data={data}
                margin={{ top: 10 }}
            >

                <ReferenceArea
                    x1={0}
                    y1={0}
                    y2={1}
                    yAxisId={"left-axis"}
                    fill='#f8e6ad'
                    opacity={.4}
                />

                <ReferenceArea
                    x1={0}
                    y1={1}
                    y2={2}
                    yAxisId={"left-axis"}
                    fill='#fdd257'
                    opacity={.5}
                    label={{ value: "Mild", position: "insideLeft", offset: 10, fill: ChartProps.indexColor(6), opacity: .8 }}
                />

                <ReferenceArea
                    x1={0}
                    y1={2}
                    y2={3}
                    yAxisId={"left-axis"}
                    fill='#ffb400'
                    opacity={.6}
                    label={{ value: "Moderate", position: "insideLeft", offset: 10, fill: ChartProps.indexColor(6), opacity: .8 }}
                />

                <ReferenceArea
                    x1={0}
                    y1={3}
                    y2={4}
                    yAxisId={"left-axis"}
                    fill='#ff8100'
                    opacity={.7}
                    label={{ value: "High", position: "insideLeft", offset: 10, fill: ChartProps.indexColor(6), opacity: .8 }}
                />

                <ReferenceArea
                    x1={0}
                    y1={4}
                    y2={5}
                    yAxisId={"left-axis"}
                    fill='#ff4c00'
                    opacity={.8}
                    label={{ value: "Severe", position: "insideLeft", offset: 10, fill: ChartProps.indexColor(6), opacity: .8 }}
                />

                <ReferenceArea
                    x1={0}
                    y1={5}
                    y2={6}
                    yAxisId={"left-axis"}
                    fill='#fe0000'
                    opacity={.9}
                    label={{ value: "Critical", position: "insideLeft", offset: 10, fill: ChartProps.indexColor(6), opacity: .8 }}
                />

                <ReferenceLine
                    x={0}
                    y={5}
                    stroke="white"
                    strokeDasharray="10"
                    yAxisId={"left-axis"}
                />

                <ReferenceLine
                    x={targetTime}
                    stroke={ChartProps.indexColor(0)}
                    yAxisId={"left-axis"}
                    label={{
                        value: `${get(form.values, "dehydration.event")} - Target Time (${targetTime} hrs)`,
                        position: 'top',
                        fill: ChartProps.indexColor(0),
                        stroke: ChartProps.indexColor(0),
                        offset: 20,
                    }}
                />

                {intersectLow &&
                    <ReferenceDot
                        x={targetTime}
                        y={intersectLow}
                        r={4}
                        fill={ChartProps.indexColor(0)}
                        stroke={ChartProps.indexColor(0)}
                        yAxisId={"left-axis"}
                    >

                        <Label
                            content={(labelProps) => targetTimeLabel({ ...labelProps, intersect: intersectLow })}
                        />

                    </ReferenceDot>
                }

                {intersectHigh &&
                    <ReferenceDot
                        x={targetTime}
                        y={intersectHigh}
                        r={4}
                        fill={ChartProps.indexColor(0)}
                        stroke={ChartProps.indexColor(0)}
                        yAxisId={"left-axis"}
                    >

                        <Label
                            content={(labelProps) => targetTimeLabel({ ...labelProps, intersect: intersectHigh })}
                        />


                    </ReferenceDot>
                }

                <ReferenceArea
                    x1={fixedNum(criticalLow - 1.9, 1)}
                    x2={fixedNum(criticalLow - 0.1, 1)}
                    y1={5.2}
                    y2={5.8}
                    yAxisId={"left-axis"}
                    fill={ChartProps.indexColor(2)}
                    radius={4}
                    opacity={1}
                    isFront
                    label={{
                        value: `${fixedNum(criticalLow, 1)} hours`,
                        position: "center",
                        fill: ChartProps.indexColor(6),
                    }}
                />

                <ReferenceArea
                    x1={fixedNum(criticalHigh - 2, 1)}
                    x2={fixedNum(criticalHigh - 0, 1)}
                    y1={5.2}
                    y2={5.8}
                    yAxisId={"left-axis"}
                    fill={ChartProps.indexColor(1)}
                    radius={4}
                    opacity={1}
                    isFront
                    label={{
                        value: `${fixedNum(criticalHigh, 1)} hours`,
                        position: "center",
                        fill: ChartProps.indexColor(6),
                    }}
                />

                {Axis.render()}

            </LineChart>

        </Chart>

    ), [props]);


}

interface ChartDehydrationProps {
    [key: string]: any,
}

export default ChartDehydration;