import React from 'react';
import { BarChart, LineChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { nearestMaxTen, nearestMinTen } from '../../functions/sharedChart';

const ChartBodyWeightAndFat: React.FC<ChartBodyWeightAndFatProps> = (props) => {

    let { data, intensity } = props;

    const Axis = new ChartAxis();

    Axis.setXByActivity("history", intensity);

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    Axis.y.push({
        axis: true,
        line: true,
        pos: "left",
        index: 0,
        key: "weight",
        label: "Body Weight",
        axisLabel: "Body Weight - kg",
        props: {
            domain: [30, 120],
        }
    });

    Axis.y.push({
        axis: true,
        line: true,
        pos: "right",
        index: 1,
        key: "manualPercentageBf",
        label: "Body Fat",
        axisLabel: "% Body Fat",
        props: {
            domain: [0, nearestMaxTen],
        }
    });


    return (

        <Chart key={Axis.x?.key}>

            <LineChart
                {...ChartProps.LineChart()}
                data={data}
            >

                {Axis.render()}

            </LineChart>

        </Chart>


    )

}

interface ChartBodyWeightAndFatProps {
    [key: string]: any,
}

export default ChartBodyWeightAndFat;