import React from 'react';
import { ComposedChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { loop } from '@dex/bubl-helpers';
import { fancyTimeFormat, nearestMaxHour, nearestMaxTen } from '../../functions/sharedChart';

const ChartCoachingWorkoutRunInterval: React.FC<ChartCoachingWorkoutRunIntervalProps> = (props) => {

    let { filters, data } = props;

    const duration = filters?.runIntervalDuration;
    const distance = filters?.runIntervalDistance;
    const metric = filters?.runIntervalMetric || [];

    const getKey = (key: string) => {

        if (!key) return;

        const item = { "Avg Pace": 'paceAvg', "Best Pace": 'paceBest', "Avg KPH": 'speedAvg', "Best KPH": 'speedBest', "Avg HR": 'hrAvg', "Peak HR": 'hrBest' }

        return item[key];

    };

    const getAxisId = (key: string) => {

        if (!key) return;

        const item = { "Avg Pace": 'Pace [min/km]', "Best Pace": 'Pace [min/km]', "Avg KPH": 'Speed [kph]', "Best KPH": 'Speed [kph]', "Avg HR": 'HR [bpm]', "Peak HR": 'HR [bpm]' }

        return item[key];

    };

    const getDomain = (key: string) => {

        if (!key) return;

        const item = { "Avg Pace": [3, nearestMaxHour], "Best Pace": [3, nearestMaxHour], "Avg HR": [80, 200], "Peak HR": [80, 200], "Avg KPH": [0, 20], "Best KPH": [0, 20] }

        return item[key];

    };

    const Axis = new ChartAxis();

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    Axis.x.label = "Week";
    Axis.x.format = undefined;
    Axis.x.key = "date";
    Axis.x.props = {
        interval: 0,
        angle: -45,
        textAnchor: "end",
    }

    if (duration == "Interval") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 0,
            key: "intervalDuration",
            axisLabel: "Interval Duration [mm:ss]",
            label: duration,
            props: {
                yAxisId: duration,
                domain: [0, 120],
                tickFormatter: fancyTimeFormat
            }
        });

    } else if (duration == "Total") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 0,
            key: "totalDuration",
            axisLabel: "Total Duration [hh:mm]",
            label: duration,
            props: {
                yAxisId: duration,
                domain: [0, 120],
                tickFormatter: fancyTimeFormat
            }
        });

    }

    if (distance == "Interval") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 1,
            key: "intervalDistance",
            axisLabel: distance + " Distance [km]",
            label: distance,
            props: {
                yAxisId: "_" + distance,
                domain: [0, nearestMaxTen],
            }
        });

    } else if (distance == "Total") {
        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 3,
            key: "totalDistance",
            axisLabel: distance + " Distance [km]",
            label: distance,
            props: {
                yAxisId: "_" + distance,
                domain: [0, nearestMaxTen],
            }
        });

    }

    loop(metric, (_metric, i) => {
        Axis.y.push({
            axis: true,
            line: true,
            pos: "right",
            index: 0,
            key: getKey(_metric),
            axisLabel: getAxisId(_metric),
            label: _metric,
            props: {
                yAxisId: getAxisId(_metric),
                domain: getDomain(_metric) || [0, "dataMax"],
                tickFormatter: _metric == "Avg Pace" || _metric == "Best Pace" ? fancyTimeFormat : undefined,
                strokeDasharray: _metric.includes("Best") || _metric.includes("Peak") ? "6 4" : "",
                stroke: ChartProps.metricColor(_metric),
                label: { fill: ChartProps.metricColor(_metric) }
            },
        });
    })

    Axis.legend = false;

    return (

        <Chart key={Axis.x?.key}>

            <ComposedChart
                {...ChartProps.BarChart()}
                data={data}
            >

                {Axis.render()}

            </ComposedChart>

        </Chart>

    )

}

interface ChartCoachingWorkoutRunIntervalProps {
    [key: string]: any,
}

export default ChartCoachingWorkoutRunInterval;