import React from 'react';
import { LineChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';

const ChartSprintMuscleOxygen: React.FC<ChartSprintMuscleOxygenProps> = (props) => {

    let { data, activity, keyIndex, intensity } = props;

    const Axis = new ChartAxis();

    Axis.setXByActivity("time", intensity);

    Axis.x.props = {
        ...Axis.x.props,
        ...props.xAxis,
        tickFormatter: undefined,
        interval: 0
    };

    Axis.x.label = "Time - Sec";

    Axis.y.push({
        axis: true,
        line: true,
        pos: "left",
        index: 0,
        key: "smo2_" + keyIndex,
        label: "SMO2",
    });

    Axis.y.push({
        axis: true,
        line: true,
        pos: "right",
        index: 1,
        key: "thb_" + keyIndex,
        label: "THb",
        props: {
            domain: [10, 14]
        }
    });

    return (

        <Chart key={Axis.x?.key}>

            <LineChart
                {...ChartProps.LineChart()}
                data={data}
            >

                {Axis.render()}

            </LineChart>

        </Chart>


    )

}

interface ChartSprintMuscleOxygenProps {
    [key: string]: any,
}

export default ChartSprintMuscleOxygen;