import React, { useMemo } from 'react';
import { LineChart, ReferenceLine } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { getDecimalTime, getKcalorieValue } from '../../functions/sharedTests';

const ChartGlycogen: React.FC<ChartGlycogenProps> = (props) => {

    let { data, activity, targetTime, fuelingRateMin, fuelingRateMax, unit } = props;

    const Axis = new ChartAxis();

    Axis.x = {
        key: "hour",
        index: 6,
        label: "Hours of " + activity,
        props: {
            dataKey: data.hour,
            key: data.hour,
            interval: 0,
            ticks: data.hour,
            type: "number",
            tickCount: data.length
        }
    };

    Axis.y.push({
        axis: true,
        line: true,
        pos: "left",
        index: 1,
        key: "carbMin",
        label: "Fueling Rate " + fuelingRateMin + unit,
        axisLabel: unit == "g/hr" ? "Glycogen Remaining - g" : "Glycogen Remaining - kcal",
        props: {
            dot: { fill: ChartProps.indexColor(1) },
            connectNulls: true,
        }
    });

    Axis.y.push({
        axis: false,
        line: true,
        pos: "left",
        index: 2,
        key: "carbMax",
        label: "Fueling Rate " + fuelingRateMax + unit,
        props: {
            dot: { fill: ChartProps.indexColor(1) },
            connectNulls: true,
        }
    });



    const decimalTargetTime = getDecimalTime(targetTime);

    function getLastNumber(arr, carb) {

        let lastNumber: any = null;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i][carb] === null) {
                break;
            }

            if (typeof arr[i][carb] === 'number') {
                lastNumber = arr[i][carb];
            }
        }

        return lastNumber;
    };

    const carbUnit = unit === "g/hr" ? " g" : " kcal";

    return useMemo(() => (

        <Chart key={Axis.x?.key}>

            <LineChart
                {...ChartProps.LineChart()}
                data={data}
            >
                {decimalTargetTime &&
                    <ReferenceLine
                        x={decimalTargetTime}
                        stroke={ChartProps.indexColor(0)}
                        yAxisId={"left-axis"}
                        label={{
                            value: `Target Time (${targetTime} hrs)`,
                            position: 'top',
                            fill: ChartProps.indexColor(0)
                        }}
                    />
                }

                <ReferenceLine
                    x={0}
                    y={unit == "g/hr" ? 50 : getKcalorieValue(50)}
                    stroke="white"
                    strokeDasharray="10"
                    yAxisId={"left-axis"}
                    label={{
                        value: `Bonking Point`,
                        position: "insideTopRight",
                        fill: "white"
                    }}
                />

                {Axis.render()}

            </LineChart>

        </Chart>

    ), [props]);


}

interface ChartGlycogenProps {
    [key: string]: any,
}

export default ChartGlycogen;