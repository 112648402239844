import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import SessionsEditTabsPanel from './SessionsEditTabsPanel';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldRange from '@dex/bubl-dash/src/lib/components/Fields/FieldRange';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldCheckbox from '@dex/bubl-dash/src/lib/components/Fields/FieldCheckbox';
import LabelWithImage from '../../../elements/LabelWithImage/LabelWithImage';
import { get, set, titleCase } from '@dex/bubl-helpers';
import { Button, FieldHidden, Heading, useUpdateEffect } from '@dex/bubl-dash';
import { calculateCoreStability, calculateLegScore } from '../../../functions/sharedGait';
import { ScoreBoard } from '../../Gaits/Components/GaitsEditTabsStability';

const SessionsEditTabsStability: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit, settings } = props;

    const getCoreImage = useCallback((id) => {

        const gender = titleCase(data.athlete.gender || "male");

        return require('../../../assets/images/Stability/Core' + id.toString() + gender + ".png");

    }, []);

    const useDefaultText = useCallback((defaultName, name: string) => {

        let text = get(settings.fit, defaultName);

        const values = form.values;

        set(values, name, text);

        form.reset(values);

    }, [props, form.hash]);

    let leftLegRating = calculateLegScore(form.values.stability?.legSquatLeft, form.values.stability?.legSquatLeftDetails);
    let rightLegRating = calculateLegScore(form.values.stability?.legSquatRight, form.values.stability?.legSquatRightDetails);
    let coreRating = calculateCoreStability(form.values.stability?.coreStability);

    if (form.values.stability?.legAssessed?.length > 0) {
        leftLegRating = 0;
        rightLegRating = 0;
    }

    useUpdateEffect(() => {
        let sum = 0;

        if (form.values.stability?.coreAssessed?.length > 0) {
            coreRating = 0;
        }

        sum = leftLegRating + rightLegRating;

        if (!Number.isFinite(coreRating)) {
        } else {
            sum += coreRating;
        }

        form.handleChange({ reset: true, name: 'stability.rating', value: sum });

    }, [leftLegRating, rightLegRating, coreRating, form.values.stability?.coreAssessed]);

    useUpdateEffect(() => {
        if (form.values.stability?.legAssessed?.length > 0) {
            form.handleChange({ reset: true, name: 'stability.legSquatLeft', value: "" });
            form.handleChange({ reset: true, name: 'stability.legSquatLeftDetails', value: [] });
            form.handleChange({ reset: true, name: 'stability.legSquatRight', value: "" });
            form.handleChange({ reset: true, name: 'stability.legSquatRightDetails', value: [] });
        }
    }, [form.values.stability?.legAssessed]);

    useUpdateEffect(() => {
        if (form.values.stability?.coreAssessed?.length > 0) {
            form.handleChange({ reset: true, name: 'stability.coreStabilityPoint', value: "" });
            form.handleChange({ reset: true, name: 'stability.coreStability', value: [] });
        }
    }, [form.values.stability?.coreAssessed]);

    return useMemo(() => (

        <SessionsEditTabsPanel
            heading={"Stability"}
            {...props}
        >


            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24 }}>
                    <Line top={null} bottom={null} />
                </Col>
                <Col col={{ xs: 24 }}>

                    <Heading size={2} space={null}>
                        <Row edge gutter={8}>
                            <Col justify='center'>Stability</Col>
                            <Col>
                                <FieldCheckbox
                                    form={form}
                                    label=""
                                    name='stability.legAssessed'
                                    required={false}
                                    defaultValue={form.defaults.stability.legAssessed}
                                    options={[
                                        { value: "Not Assessed/Reassessed", label: "Not Assessed/Reassessed" }
                                    ]}
                                    wrapClass="inline-label"
                                />
                            </Col>
                        </Row>
                    </Heading>

                </Col>

                <Col col={{ xs: 24, md: 12, lg: 8 }}>

                    <FieldRadio
                        form={form}
                        label={"Single Leg Squat (Left)"}
                        name='stability.legSquatLeft'
                        required={false}
                        defaultValue={form.values.stability.legSquatLeft}
                        key={form.values.stability.legSquatLeft}
                        options={[
                            { value: "Stable", label: "Stable" },
                            { value: "Some Instability", label: "Some Instability" },
                            { value: "Lots of Instability", label: "Lots of Instability" }
                        ]}
                        button={true}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 12 }} key={form.values.stability.legSquatLeftDetails}>
                            <FieldCheckbox
                                form={form}
                                name='stability.legSquatLeftDetails'
                                list={true}
                                defaultValue={form.values.stability.legSquatLeftDetails}
                                key={form.values.stability.legSquatLeftDetails}
                                options={[
                                    { value: "Torso", label: "Torso" },
                                    { value: "Hip", label: "Hip" },
                                    { value: "Knee", label: "Knee" },
                                    { value: "Foot", label: "Foot" },
                                    { value: "Hands", label: "Hands" },
                                ]}
                            />
                        </Col>

                        <Col col={{ xs: 12 }}>
                            <ScoreBoard
                                score={leftLegRating}
                                fullMark={3}
                            />
                            <FieldHidden
                                form={form}
                                name='stability.leftLegRating'
                                value={leftLegRating}
                            />
                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24, md: 12, lg: 8 }}>

                    <FieldRadio
                        form={form}
                        label={"Single Leg Squat (Right)"}
                        name='stability.legSquatRight'
                        required={false}
                        defaultValue={form.values.stability.legSquatRight}
                        key={form.values.stability.legSquatRight}
                        options={[
                            { value: "Stable", label: "Stable" },
                            { value: "Some Instability", label: "Some Instability" },
                            { value: "Lots of Instability", label: "Lots of Instability" }
                        ]}
                        button={true}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 12 }}>
                            <FieldCheckbox
                                form={form}
                                name='stability.legSquatRightDetails'
                                list={true}
                                defaultValue={form.values.stability.legSquatRightDetails}
                                key={form.values.stability.legSquatRightDetails}
                                options={[
                                    { value: "Torso", label: "Torso" },
                                    { value: "Hip", label: "Hip" },
                                    { value: "Knee", label: "Knee" },
                                    { value: "Foot", label: "Foot" },
                                    { value: "Hands", label: "Hands" },
                                ]}
                            />
                        </Col>

                        <Col col={{ xs: 12 }}>
                            <ScoreBoard
                                score={rightLegRating}
                                fullMark={3}
                            />
                            <FieldHidden
                                form={form}
                                name='stability.rightLegRating'
                                value={rightLegRating}
                            />
                        </Col>

                    </Row>

                </Col>

                <Col col={{ md: 24, lg: 8 }} align='center'>
                    {form.values.athlete.gender === 'female' &&

                        <img src={require('../../../assets/images/Stability/StabilitySquatFemale.png')}
                            alt='squat-female'
                            className="stabilityImage"
                        />

                    }

                    {form.values.athlete.gender === 'male' &&

                        <img src={require('../../../assets/images/Stability/StabilitySquatMale.png')}
                            alt='squat-male'
                            className="stabilityImage"
                        />


                    }
                </Col>

                <Col col={{ xs: 24 }}>
                    <Line />
                </Col>

                <Col col={{ xs: 16 }}>

                    <Heading size={2} space={null}>
                        <Row edge gutter={8}>
                            <Col justify='center'>Core Stability</Col>
                            <Col>
                                <FieldCheckbox
                                    form={form}
                                    label=""
                                    name='stability.coreAssessed'
                                    required={false}
                                    defaultValue={form.defaults.stability.coreAssessed}
                                    options={[
                                        { value: "Not Assessed/Reassessed", label: "Not Assessed/Reassessed" }
                                    ]}
                                    wrapClass="inline-label"
                                />
                            </Col>
                        </Row>
                    </Heading>

                    <FieldCheckbox
                        form={form}
                        label={""}
                        name='stability.coreStability'
                        required={false}
                        defaultValue={form.values.stability.coreStability}
                        key={form.values.stability.coreStability}
                        options={[
                            { value: "1", label: <LabelWithImage image={getCoreImage(1)} text={"Level 1"} /> },
                            { value: "2", label: <LabelWithImage image={getCoreImage(2)} text={"Level 2"} /> },
                            { value: "3", label: <LabelWithImage image={getCoreImage(3)} text={"Level 3"} /> },
                            { value: "4", label: <LabelWithImage image={getCoreImage(4)} text={"Level 4"} /> },
                            { value: "5", label: <LabelWithImage image={getCoreImage(5)} text={"Level 5"} /> },
                        ]}
                        button={true}
                        wrapClass={"checkbox-with-image"}
                    />

                </Col>

                <Col col={{ xs: 8 }} justify='center'>
                    <ScoreBoard
                        score={coreRating}
                        fullMark={5}
                    />
                    <FieldHidden
                        form={form}
                        name='stability.coreStabilityPoint'
                        value={coreRating}
                    />
                </Col>

                <Col col={{ xs: 24 }}>
                    <Line />
                </Col>

                <Col col={{ xs: 24, lg: 16 }}>

                    <FieldRange
                        form={form}
                        label={"Rating (Suggested: " + form.values.stability.rating + ")"}
                        name='stability.rating'
                        required={false}
                        defaultValue={form.defaults.stability.rating}
                        key={form.values.stability.rating}
                        min={0}
                        max={10}
                        step={.5}
                        minLabel={"0: Poor"}
                        maxLabel={"10: Good"}
                    />

                </Col>

                <Col col={{ xs: 24, lg: 16 }}>

                    <FieldTextArea
                        form={form}
                        label={"Comments"}
                        name={'stability.comments'}
                        required={false}
                        defaultValue={form.defaults.stability.comments}
                        rows={4}
                        maxLength={400}
                        wrapClass={"manual-input"}
                    />

                    <Row edge={true}>

                        <Button
                            label={'Use Default Text'}
                            onClick={useDefaultText.bind(null, `stabilityComment`, "stability.comments")}
                            type={'faded'}
                            confirm={form.values.stability.comments ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current comment' } : undefined}
                        />

                    </Row>

                </Col>

            </Row>

        </SessionsEditTabsPanel>

    ), [props]);

}

export default SessionsEditTabsStability;
