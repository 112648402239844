import React from 'react';
import { BarChart, LineChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';

const ChartRestingBp: React.FC<ChartRestingBpProps> = (props) => {

    let { data, intensity } = props;

    const Axis = new ChartAxis();

    Axis.setXByActivity("history", intensity);

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    //set y axis
    Axis.y.push({
        axis: true,
        bar: true,
        pos: "left",
        index: 0,
        key: "restingBpSystolic",
        axisLabel: "Blood Pressure - mmHg",
        label: "Sys BP",
    });

    Axis.y.push({
        axis: false,
        bar: true,
        pos: "left",
        index: 1,
        key: "restingBpDiastolic",
        label: "Dys BP",
    });

    return (

        <Chart key={Axis.x?.key}>

            <BarChart
                {...ChartProps.BarChart()}
                data={data}
            >

                {Axis.render()}

            </BarChart>

        </Chart>


    )

}

interface ChartRestingBpProps {
    [key: string]: any,
}

export default ChartRestingBp;