import { slug } from '@dex/bubl-helpers';
import React from 'react';
import { LineChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { fancyTimeFormat } from '../../functions/sharedChart';

const ChartBeOverTime: React.FC<ChartBeOverTimeProps> = (props) => {

    let { data, activity, date, index, intensity, isRunningPeak, filters } = props;

    const Axis = new ChartAxis();

    const xAxis = filters?.xAxis;

    if (isRunningPeak) {
        if (xAxis == "time") {
            Axis.x = {
                key: "time",
                index: 6,
                label: "Time - min",
                props: {
                    tickFormatter: fancyTimeFormat
                },

            };
        } else if (xAxis == "grade") {
            Axis.x = {
                key: "grade",
                index: 6,
                label: "% Grade",
                props: {
                    tickFormatter: (value) => value + "%",
                },
            };
        } else if (xAxis == "maxSpeed") {
            Axis.x = {
                key: "speed",
                index: 6,
                label: "Speed - KPH (Max Test)",
                props: {
                },
            };
        } else {
            Axis.x = {
                key: "submaxSpeed_" + slug(index),
                index: 6,
                label: "Speed - KPH (Submax Test)",
                props: {
                },
            };
        }

    } else {
        Axis.setXByActivity(activity, intensity);
    }

    const historyData = Axis.prepareHistoryData(data, slug(date), slug(index));

    if (isRunningPeak && xAxis == "grade") {
        //Axis.x.props = { type: "category", interval: 0 };
    }

    Axis.x.props = { ...Axis.x.props, ...props.xAxis, };

    Axis.y.push({
        axis: true,
        line: true,
        pos: "left",
        index: 0,
        key: "calc_tvfev1_" + slug(date),
        label: date,
        axisLabel: "TV/FEv1 - %",
        props: {
            domain: [0, 100],
            connectNulls: true,
        }
    });

    if (date !== index) {

        Axis.y.push({
            axis: false,
            line: true,
            pos: "left",
            index: 1,
            key: "calc_tvfev1_" + slug(index),
            label: index,
            axisLabel: "Rf/FEv1 - %",
            props: {
                strokeDasharray: "6 4",
                connectNulls: true,
            }
        });

    };

    return (

        <Chart
            key={Axis.x.key}
        >

            <LineChart
                {...ChartProps.LineChart()}
                data={historyData}
            >

                {Axis.render()}

            </LineChart>

        </Chart>


    )

}

interface ChartBeOverTimeProps {
    [key: string]: any,
}

export default ChartBeOverTime;