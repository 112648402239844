import React, { useEffect } from 'react';
import { Bar, BarChart, Cell, LabelList, Legend } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { fixedNum, loop } from '@dex/bubl-helpers';
import { nearestMaxHundered, nearestMaxTen, nearestMinHundered } from '../../functions/sharedChart';

const ChartPerformanceMetric: React.FC<ChartPerformanceMetricProps> = (props) => {

    let { data, type, metricCompareTo, FTPPower } = props;

    type = type || "Power";

    const Axis = new ChartAxis();

    Axis.x = {
        key: "photoCaption",
        label: "Position",
        index: 6,
        format: undefined
    }

    Axis.x.props = {
        interval: 0,
        type: "category",
        ticks: data?.map(item => item?.photoCaption),
    }

    loop(data, (item, index) => {

        item.powerDiff = item?.power - data[(metricCompareTo - 1)]?.power;
        item.powerDiffPercent = fixedNum((item.powerDiff / data[(metricCompareTo - 1)]?.power) * 100, 0);

        const percentFTP = (item?.power / FTPPower) * 100;

        item.percentFTP = fixedNum(percentFTP, 0);

    });

    if (type === "Power") {
        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 0,
            key: "power",
            label: "Power",
            axisLabel: "Power - W",
            props: {
                barSize: 80,
                domain: [0, nearestMaxHundered],
            }
        });
    } else if (type === "Speed") {
        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 1,
            key: "speed",
            label: "Speed",
            axisLabel: "Speed - kph",
            props: {
                barSize: 80,
                domain: [0, nearestMaxTen],
            }
        });
    } else if (type === "CdA") {
        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 4,
            key: "cdA",
            label: "CdA",
            axisLabel: "CdA",
            props: {
                barSize: 80,
            }
        });
    } else if (type === "W Diff") {
        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 9,
            key: "powerDiff",
            label: "W Difference",
            axisLabel: "W Difference",
            props: {
                domain: [nearestMinHundered, nearestMaxHundered],
            }
        });
        Axis.y.push({
            axis: true,
            bar: true,
            pos: "right",
            index: 6,
            key: "powerDiffPercent",
            label: "% W Difference",
            axisLabel: "% W Difference",
            props: {
                tickFormatter: (val) => val + "%",
                domain: [nearestMinHundered, nearestMaxHundered],
                suffix: " %"
            }
        });
    } else if (type === "% FTP") {
        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 5,
            key: "percentFTP",
            label: "% FTP",
            axisLabel: "% FTP",
            props: {
                tickFormatter: (val) => val + "%",
                barSize: 80,
                suffix: " %",
            }
        });
    }

    Axis.legend = false;
    Axis.showLabel = true;

    return (

        <Chart key={Axis.x?.key}>

            <BarChart
                {...ChartProps.BarChart()}
                data={data}
            >

                {Axis.render()}

            </BarChart>

        </Chart>


    )

}

interface ChartPerformanceMetricProps {
    [key: string]: any,
}

export default ChartPerformanceMetric;