import React from 'react';
import { LineChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import * as _ from 'lodash';
import ChartAxis from '../Chart/ChartAxis';
import { slug } from '@dex/bubl-helpers';
import { fancyTimeFormat } from '../../functions/sharedChart';

const ChartPerformanceSpeedGrade: React.FC<ChartLactateProps> = (props) => {

    let { data, activity, keyIndex, intensity, date, index, chartType, axisActivity } = props;

    const Axis = new ChartAxis();

    Axis.setXByActivity((axisActivity ? axisActivity : "time"), intensity);

    const historyData = Axis.prepareHistoryData(data, slug(date), slug(index));

    Axis.x.props = {
        ...Axis.x.props,
        ...props.xAxis,
    };

    Axis.x.key = "timeM";

    Axis.x.props = {
        type: "number",
        ticks: data.map((item) => item[Axis.x.key || "x"]),
        domain: ["dataMin", "dataMax"],
        // tickFormatter: ""
        interval: 0
    }

    if (activity === "Running") {

        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: "speed",
            label: "Speed",
            axisLabel: "Speed - kph",
            props: {
                domain: [4, 20]
            }
        });

        Axis.y.push({
            axis: true,
            line: true,
            pos: "right",
            index: 2,
            key: "grade",
            label: "Grade",
            props: {
                domain: [0, 16]
            }
        });

        // Axis.y.push({
        //     axis: true,
        //     line: true,
        //     pos: "right",
        //     index: 1,
        //     key: "strideFrequency",
        //     label: "Strides",
        //     axisLabel: "Strides per min",
        //     props: {
        //         domain: [60, 100]
        //     }
        // });

    } else {
        if (chartType == "Power") {
            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: 0,
                key: "power_raw_" + slug(date),
                label: "Power " + date,
                axisLabel: "Power - W",
                props: {
                    connectNulls: true,
                }
            })
        } else {
            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: 1,
                key: "rpm_" + slug(date),
                label: "RPM " + date,
                axisLabel: "RPM",
                props: {
                    connectNulls: true,
                }
            });
        }
    }


    if (date !== index) {

        chartType == "Power" ? Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: "power_raw_" + slug(index),
            label: "Power " + index,
            axisLabel: "Power - W",
            props: {
                strokeDasharray: "6 4",
                connectNulls: true,
            }
        }) : null;

        chartType == "Rpm" ? Axis.y.push({
            axis: true,
            line: true,
            pos: "right",
            index: 1,
            key: "rpm_" + slug(index),
            label: "RPM " + index,
            props: {
                strokeDasharray: "6 4",
                connectNulls: true,
            }
        }) : null;
    }

    return (

        <Chart key={Axis.x?.key}>

            <LineChart
                {...ChartProps.LineChart()}
                data={!index && activity === "Running" ? data : historyData}
            >

                {Axis.render()}

            </LineChart>

        </Chart>


    )

}

interface ChartLactateProps {
    [key: string]: any,
}

export default ChartPerformanceSpeedGrade;