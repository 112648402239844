import { slug } from '@dex/bubl-helpers';
import React from 'react';
import { LineChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { fancyTimeFormat, nearestMaxTen } from '../../functions/sharedChart';

const ChartVo2Max: React.FC<ChartVo2MaxProps> = (props) => {

    let { data, activity, index, date, intensity, isRunningPeak, filters } = props;

    const Axis = new ChartAxis();

    const xAxis = filters?.xAxis;

    if (isRunningPeak) {
        if (xAxis == "time") {
            Axis.x = {
                key: "time",
                index: 6,
                label: "Time - min",
                props: {
                    tickFormatter: fancyTimeFormat
                },

            };
        } else if (xAxis == "grade") {
            Axis.x = {
                key: "grade",
                index: 6,
                label: "% Grade",
                props: {
                    tickFormatter: (value) => value + "%",
                },
            };
        } else if (xAxis == "maxSpeed") {
            Axis.x = {
                key: "speed",
                index: 6,
                label: "Speed - KPH (Max Test)",
                props: {
                },
            };
        } else {
            Axis.x = {
                key: "submaxSpeed",
                index: 6,
                label: "Speed - KPH (Submax Test)",
                props: {
                },
            };
        }

    } else {
        Axis.setXByActivity(activity, intensity);
    }

    const historyData = slug(date) == slug(index) ? data : Axis.prepareHistoryData(data, slug(date), slug(index));

    if (isRunningPeak && xAxis == "grade") {
        //Axis.x.props = { type: "category", interval: 1 };
    }

    if (isRunningPeak && (xAxis == "maxSpeed" || xAxis == "submaxSpeed")) {
        Axis.x.props = { type: "category", interval: 1 };
    }

    Axis.x.props = {
        ...Axis.x.props,
        ...props.xAxis,
    };

    Axis.y.push({
        axis: true,
        line: true,
        pos: "left",
        index: 0,
        key: "vo2relative_" + slug(date),
        label: date,
        axisLabel: "VO2 - ml/kg²min",
        props: {
            connectNulls: true,
            domain: [10, nearestMaxTen]
        }
    });

    if (date !== index) {

        Axis.y.push({
            axis: false,
            line: true,
            pos: "left",
            index: 1,
            key: "vo2relative_" + slug(index),
            label: index,
            props: {
                strokeDasharray: "6 4",
                connectNulls: true,
                labelPosition: "bottom"
            }
        });

    };

    return (

        <Chart
            key={Axis.x.key}
        >

            <LineChart
                {...ChartProps.LineChart()}
                data={historyData}
            >

                {Axis.render()}

            </LineChart>

        </Chart>


    )

}

interface ChartVo2MaxProps {
    [key: string]: any,
}

export default ChartVo2Max;