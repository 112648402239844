import React from 'react';
import { BarChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';

const ChartPerformanceTracking: React.FC<ChartPerformanceTrackingProps> = (props) => {

    let { data, activity, date, index, intensity, chart, type } = props;

    const Axis = new ChartAxis();

    Axis.legend = false;

    Axis.setXByActivity("history", intensity);

    const trackingData = Axis.prepareTrackingData(data, 'threshold');

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    if (chart == "HR") {

        if (type == "bpm") {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 5,
                key: "perfThresholdHr",
                label: date,
                axisLabel: "HR - Bpm"
            });
        } else {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 5,
                key: "perfThresholdPercentHR",
                label: date,
                axisLabel: "HR - %"
            });
        }

    } else if (chart == "Lactate") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 2,
            key: "lactateThreshold",
            label: date,
            axisLabel: "Lactate - mmol/L"
        });

    } else if (chart == "VO2") {

        if (type == "relative") {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 1,
                key: "perfThresholdVO2",
                label: date,
                axisLabel: "Vo2 - ml/kg*min"
            });
        } else {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 1,
                key: "perfThresholdPercentVO2",
                label: date,
                axisLabel: "Vo2 - %"
            });
        }

    } else if (chart == "Speed") {

        if (type == "speed") {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 0,
                key: "perfThreshold",
                label: date,
                axisLabel: "Speed - kph"
            });
        } else {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 0,
                key: "perfThresholdPace",
                label: date,
                axisLabel: "Speed - min/km"
            });
        }

    } else if (chart == "Power") {

        if (type == "Watts") {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 0,
                key: "perfThreshold",
                label: date,
                axisLabel: "Power - w",
            });
        } else {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 0,
                key: "perfThresholdPerKg",
                label: date,
                axisLabel: "Power - w/kg"
            });
        }

    } else if (chart == "Carb Burn") {

        if (type == "kcal") {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 4,
                key: "carbKcalThreshold",
                label: date,
                axisLabel: "Carb Burn - kcal/hr"
            });
        } else {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 4,
                key: "carbThreshold",
                label: date,
                axisLabel: "Carb Burn - g/hr"
            });
        }

    } else if (chart == "Fat Burn") {

        if (type == "kcal") {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 4,
                key: "fatKcalThreshold",
                label: date,
                axisLabel: "Fat Burn - kcal/hr"
            });
        } else {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 4,
                key: "fatThreshold",
                label: date,
                axisLabel: "Fat Burn - g/hr"
            });
        }
    }

    return (

        <Chart key={Axis.x?.key}>

            <BarChart
                {...ChartProps.BarChart()}
                data={trackingData}
            >
                {Axis.render()}

            </BarChart>

        </Chart>


    )

}

interface ChartPerformanceTrackingProps {
    [key: string]: any,
}

export default ChartPerformanceTracking;