import React from 'react';
import { LineChart, ReferenceLine, Label } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import * as _ from 'lodash';
import ChartAxis from '../Chart/ChartAxis';
import { fixedNum, get, nl2br, slug } from '@dex/bubl-helpers';
import { fancyTimeFormat } from '../../functions/sharedChart';

const ChartMuscleLactate: React.FC<ChartMuscleLactateProps> = (props) => {

    let { data, activity, date, index, intensity, testData, isRunningPeak, filters } = props;

    const base = fixedNum(get(testData, `lactate.measures.base`));
    const threshold = fixedNum(get(testData, `lactate.measures.1stSteepRise`));
    const type = testData.type;

    const chartType = filters.useData;

    const Axis = new ChartAxis();

    const xAxis = filters?.xAxis;

    if (isRunningPeak) {
        if (xAxis == "time") {
            Axis.x = {
                key: "time",
                index: 6,
                label: "Time - min",
                props: {
                    tickFormatter: fancyTimeFormat
                },

            };
        } else if (xAxis == "grade") {
            Axis.x = {
                key: "grade",
                index: 6,
                label: "% Grade",
                props: {
                    domain: ["dataMin", "dataMax"],
                    type: "number",
                },
            };
        } else if (xAxis == "maxSpeed") {
            Axis.x = {
                key: "speed",
                index: 6,
                label: "Speed - KPH (Max Test)",
                props: {
                },
            };
        } else {
            Axis.x = {
                key: "submaxSpeed_" + slug(index),
                index: 6,
                label: "Speed - KPH (Submax Test)",
                props: {
                },
            };
        }

    } else {
        Axis.setXByActivity(activity, intensity);
    }

    const historyData = Axis.prepareHistoryData(data, slug(date), slug(index));

    Axis.x.props = {
        ...Axis.x.props,
        ...props.xAxis,
    };

    const row_date = historyData[0];
    const row_index = historyData[1];

    const date_slug = slug(date);
    const index_slug = slug(index);

    if (chartType == "Lactate") {
        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: row_date["lactate_" + date_slug] ? "lactate_" + date_slug : "lactatePost_" + date_slug,
            label: "Lactate " + date,
            axisLabel: "mmol/L",
            props: {
                // domain: [0, 20],
                connectNulls: true,
            }
        });
    } else {
        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 1,
            key: "rpe_" + date_slug,
            label: "RPE " + date,
            axisLabel: "RPE",
            props: {
                domain: [0, 10],
                connectNulls: true,
            }
        });
    }

    if (date !== index) {
        if (chartType == "Lactate") {
            Axis.y.push({
                axis: false,
                line: true,
                pos: "left",
                index: 0,
                key: row_index["lactate_" + index_slug] ? "lactate_" + index_slug : "lactatePost_" + index_slug,
                label: "Lactate " + index,
                axisLabel: "mmol/L",
                props: {
                    strokeDasharray: "6 4",
                    connectNulls: true,
                }
            });
        } else {
            Axis.y.push({
                axis: false,
                line: true,
                pos: "left",
                index: 1,
                key: "rpe_" + index_slug,
                label: "RPE " + index,
                axisLabel: "RPE",
                props: {
                    strokeDasharray: "6 4",
                    connectNulls: true,
                }
            });
        }
    }

    return (

        <Chart
            key={Axis.x.key}
        >

            <LineChart
                {...ChartProps.LineChart()}
                data={historyData}
            >

                {base &&
                    <ReferenceLine
                        x={base}
                        stroke={ChartProps.indexColor(2)}
                        yAxisId={"left-axis"}
                        strokeDasharray="3"
                    >
                        <Label
                            value={"Start Tempo"}
                            position='top'
                            fill={ChartProps.indexColor(2)}
                            stroke={ChartProps.indexColor(2)}
                            offset={20}
                        />
                        <Label
                            value={"(Lt1)"}
                            position='top'
                            fill={ChartProps.indexColor(2)}
                            stroke={ChartProps.indexColor(2)}
                        />
                    </ReferenceLine>
                }

                {threshold &&
                    <ReferenceLine
                        x={threshold}
                        stroke={ChartProps.indexColor(2)}
                        yAxisId={"left-axis"}
                        strokeDasharray="3"
                    >
                        <Label
                            value={"Threshold"}
                            position='top'
                            fill={ChartProps.indexColor(2)}
                            stroke={ChartProps.indexColor(2)}
                            offset={20}
                        />
                        <Label
                            value={"(Lt2)"}
                            position='top'
                            fill={ChartProps.indexColor(2)}
                            stroke={ChartProps.indexColor(2)}
                        />
                    </ReferenceLine>
                }

                {Axis.render()}

            </LineChart>

        </Chart>


    )

}

interface ChartMuscleLactateProps {
    [key: string]: any,
}

export default ChartMuscleLactate;