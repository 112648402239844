import { fixedNum, get, loop } from '@dex/bubl-helpers';
import React, { useMemo } from 'react';
import { ComposedChart, ReferenceLine, Label } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { fancyTimeFormat } from '../../functions/sharedChart';

const ChartEnergy: React.FC<ChartEnergyProps> = (props) => {

    let { data, activity, type, form, intensity, testData, isRunningPeak, filters } = props;

    const xAxis = filters?.xAxis;
    const unit = filters?.useData;

    const lower = form ? get(form, "values.energy.lowerRefuel", 10) : testData?.test?.energy?.lowerRefuel;
    const upper = form ? get(form, "values.energy.upperRefuel", 30) : testData?.test?.energy?.upperRefuel;

    const fatmax = form ? fixedNum(get(form.values, "energy.fatmaxLoad")) : "";
    const mep = form ? fixedNum(get(form.values, "energy.mepLoad")) : "";

    const Axis = new ChartAxis();

    if (isRunningPeak) {
        if (xAxis == "time") {
            Axis.x = {
                key: "time",
                index: 6,
                label: "Time - min",
                props: {
                    tickFormatter: fancyTimeFormat
                },

            };
        } else if (xAxis == "grade") {
            Axis.x = {
                key: "grade",
                index: 6,
                label: "% Grade",
                props: {
                },
            };
        } else if (xAxis == "maxSpeed") {
            Axis.x = {
                key: "speed",
                index: 6,
                label: "Speed - KPH (Max Test)",
                props: {
                    type: "number",
                    ticks: data.map((item) => item.speed),
                    domain: ["dataMin", "dataMax"],
                },
            };
        } else {
            Axis.x = {
                key: "submaxSpeed",
                index: 6,
                label: "Speed - KPH (Submax Test)",
                props: {
                    type: "number",
                    ticks: data.map((item) => item.submaxSpeed),
                    domain: ["dataMin", "dataMax"],
                },
            };
        }

    } else {
        Axis.setXByActivity(activity, intensity);

        Axis.x.props = {
            type: "number",
            ticks: data.map((item) => item[Axis.x.key || "x"]),
            domain: ["dataMin", "dataMax"],
            tickFormatter: Axis.x.key === "time" ? fancyTimeFormat : undefined
        }
    }

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    const _data = useMemo(() => {

        loop(data, (row, index) => {

            data[index].calc_cho_lower = fixedNum(row.calc_cho_10 / 10 * lower, 0);
            data[index].calc_cho_upper = fixedNum(row.calc_cho_30 / 30 * upper, 0);

            data[index].calc_eecho_lower = fixedNum(row.calc_eecho_10 / 10 * lower, 0);
            data[index].calc_eecho_upper = fixedNum(row.calc_eecho_30 / 30 * upper, 0);

            data[index].calc_cho_lower_upper = [data[index].calc_cho_lower, data[index].calc_cho_upper];
            data[index].calc_eecho_lower_upper = [data[index].calc_eecho_lower, data[index].calc_eecho_upper];

        });

        return data;

    }, [data]);

    if (type === "burn") {

        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 4,
            key: (unit === "g") ? "cho_fat_pro" : "ee",
            label: "Total",
            axisLabel: (unit === "g") ? "g/hr" : "Kcal/hr"
        });

        Axis.y.push({
            axis: false,
            line: true,
            pos: "left",
            index: 1,
            key: (unit === "g") ? "cho" : "eecho",
            label: "Carb",
        });

        Axis.y.push({
            axis: false,
            line: true,
            pos: "left",
            index: 0,
            key: (unit === "g") ? "fat" : "eefat",
            label: "Fat",
        });


    } else if (type === "mep") {

        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 1,
            key: "calc_cho",
            label: "% Carb",
            axisLabel: "% of Total Energy",
            props: {
                type: "number",
                domain: [0, 100]
            }
        });

        Axis.y.push({
            axis: false,
            line: true,
            pos: "left",
            index: 0,
            key: "calc_fat",
            label: "% Fat",
        });

    } else if (type === "fueling") {

        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 5,
            key: (unit === "g") ? "calc_cho_lower" : "calc_eecho_lower",
            label: `${lower}% Refuel`,
            axisLabel: (unit === "g") ? "g/hr" : "Kcal/hr",
            props: {
                domain: (unit === "g") ? [0, 60] : [0, 300],
                stroke: ChartProps.indexColor(5)
            },
        });

        Axis.y.push({
            axis: false,
            line: true,
            pos: "left",
            index: 4,
            key: (unit === "g") ? "calc_cho_upper" : "calc_eecho_upper",
            label: `${upper}% Refuel`,
            props: {
                stroke: ChartProps.indexColor(4)
            }
        });

        Axis.y.push({
            axis: false,
            area: true,
            pos: "left",
            index: 4,
            hideToolTip: true,
            key: (unit === "g") ? "calc_cho_lower_upper" : "calc_eecho_lower_upper",
            label: "CHO 10 - 30",
            props: {
                fill: ChartProps.indexColor(4)
            }
        });

    };

    return (

        <Chart
            key={Axis.x.key}
        >

            <ComposedChart
                {...ChartProps.LineChart()}
                data={_data}
            >

                {Axis.render()}

                {fatmax && type === "burn" &&
                    <ReferenceLine
                        x={fatmax}
                        stroke={ChartProps.indexColor(2)}
                        yAxisId={"left-axis"}
                        strokeDasharray="3"
                    >
                        <Label
                            value={"FATMAX"}
                            position='top'
                            fill={ChartProps.indexColor(2)}
                            stroke={ChartProps.indexColor(2)}
                            offset={20}
                        />
                    </ReferenceLine>
                }

                {mep && type === "mep" &&
                    <ReferenceLine
                        x={mep}
                        stroke={ChartProps.indexColor(2)}
                        yAxisId={"left-axis"}
                        strokeDasharray="3"
                    >
                        <Label
                            value={"MEP"}
                            position='top'
                            fill={ChartProps.indexColor(2)}
                            stroke={ChartProps.indexColor(2)}
                            offset={20}
                        />
                    </ReferenceLine>
                }

            </ComposedChart>

        </Chart>


    )

}

interface ChartEnergyProps {
    [key: string]: any,
}

export default ChartEnergy;