import React from 'react';
import { LineChart, ReferenceLine, Label } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { loop } from '@dex/bubl-helpers';

const ChartSprintMuscleLactate: React.FC<ChartSprintMuscleLactateProps> = (props) => {

    let { data, activity, intensity, peak } = props;

    const Axis = new ChartAxis();

    Axis.x = {
        key: "manualTime",
        index: 6,
        label: "Recovery Time - Min",
        props: {
            ...props.xAxis,
            interval: 0
        }
    }

    Axis.y.push({
        axis: true,
        line: true,
        pos: "left",
        index: 0,
        key: "lactate",
        label: "mmol/L",
        axisLabel: "Lactate - mmol/L",
        props: {
            domain: [0, 10]
        }
    });

    let peakIndex;

    loop(data, (row, index) => {
        if (row?.manualTime === peak && !peakIndex) peakIndex = index
    })

    return (

        <Chart key={Axis.x?.key}>

            <LineChart
                {...ChartProps.LineChart()}
                data={data}
            >

                {peakIndex &&
                    <ReferenceLine
                        x={peakIndex}
                        stroke={ChartProps.indexColor(2)}
                        yAxisId={"left-axis"}
                        strokeDasharray="3"
                    >
                        <Label
                            value={"Peak Lactate"}
                            position='top'
                            fill={ChartProps.indexColor(2)}
                            stroke={ChartProps.indexColor(2)}
                        />
                    </ReferenceLine>
                }

                {Axis.render()}

            </LineChart>

        </Chart>


    )

}

interface ChartSprintMuscleLactateProps {
    [key: string]: any,
}

export default ChartSprintMuscleLactate;