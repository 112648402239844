import React from 'react';
import { LineChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';

const ChartAnalysis: React.FC<ChartAnalysisProps> = (props) => {

    let { data, activity, type, intensity } = props;

    const Axis = new ChartAxis();

    Axis.setXByActivity(activity, intensity);

    if (Axis.x.key !== "time") {
        Axis.x.props = {
            type: "number",
            ticks: data.map((item) => item[Axis.x.key || "x"]),
            domain: ["dataMin", "dataMax"],
        }
    }

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    if (type === "hr") {

        Axis.y.push({
            axis: false,
            circle: true,
            pos: "left",
            index: 2,
            key: "hr",
            label: "HR",
        });

        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: "reg_hr",
            label: "HR (Linear)",
            axisLabel: "HR",
        });

    } else if (type === "vo2") {

        Axis.y.push({
            axis: false,
            circle: true,
            pos: "left",
            index: 2,
            key: "vo2relative",
            label: "Vo2",
        });

        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: "reg_vo2",
            label: "Vo2 (Linear)",
            axisLabel: "Vo2",
        });

    } else if (type === "vo2hr") {

        Axis.x = {
            key: "hr",
            index: 6,
            label: "HR",
            props: {
                ...Axis.x.props, ...props.xAxis,
                ticks: data.map((item) => item["hr"]),
            }
        }

        Axis.y.push({
            axis: false,
            circle: true,
            pos: "left",
            index: 2,
            key: "vo2absolute",
            label: "Vo2-HR",
        });

        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: "reg_vo2absolute",
            label: "Vo2-HR (Linear)",
            axisLabel: "Vo2-HR",
        });

    } else if (type === "carbg") {

        Axis.y.push({
            axis: false,
            circle: true,
            pos: "left",
            index: 2,
            key: "cho",
            label: "Carb",
        });

        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: "reg_cho",
            label: "Carb (Poly)",
            axisLabel: "Carb Burn Rate",
        });

    } else if (type === "carbkcal") {

        Axis.y.push({
            axis: false,
            circle: true,
            pos: "left",
            index: 2,
            key: "eecho",
            label: "Carb",
        });

        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: "reg_eecho",
            label: "Carb (Poly)",
            axisLabel: "Carb Burn Rate",
        });

    } else if (type === "fatg") {

        Axis.y.push({
            axis: false,
            circle: true,
            pos: "left",
            index: 2,
            key: "fat",
            label: "Fat",
        });

        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: "reg_fat",
            label: "Fat (Poly)",
            axisLabel: "Fat Burn Rate",
        });

    } else if (type === "fatkcal") {

        Axis.y.push({
            axis: false,
            circle: true,
            pos: "left",
            index: 2,
            key: "eefat",
            label: "Fat",
        });

        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: "reg_eefat",
            label: "Fat (Poly)",
            axisLabel: "Fat Burn Rate",
        });

    } else if (type === "lactate") {

        Axis.y.push({
            axis: false,
            circle: true,
            pos: "left",
            index: 2,
            key: "lactate",
            label: "Lactate",
            axisLabel: "Lactate",
        });

        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: "poly_lactate",
            label: "Poly. Lactate",
            axisLabel: "Lactate",
        });

        Axis.y.push({
            axis: true,
            line: true,
            pos: "right",
            index: 1,
            key: "smo2_" + (props.smo2Use === "b" ? 2 : 1),
            label: "SMO2",
        });
    } else if (type === "lactatePost") {

        Axis.y.push({
            axis: false,
            circle: true,
            pos: "left",
            index: 2,
            key: "lactatePost",
            label: "Lactate",
            axisLabel: "Lactate",
        });

        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: "poly_lactate",
            label: "Poly. Lactate",
            axisLabel: "Lactate",
        });

        Axis.y.push({
            axis: true,
            line: true,
            pos: "right",
            index: 1,
            key: "smo2_" + (props.smo2Use === "b" ? 2 : 1),
            label: "SMO2",
        });
    }

    return (

        <Chart key={Axis.x?.key}>

            <LineChart
                {...ChartProps.LineChart()}
                data={data}
            >

                {Axis.render()}

            </LineChart>

        </Chart>


    )

}

interface ChartAnalysisProps {
    [key: string]: any,
}

export default ChartAnalysis;