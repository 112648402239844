import React, { useEffect } from 'react';
import { LineChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { slug } from '@dex/bubl-helpers';
import { fancyTimeFormat } from '../../functions/sharedChart';

const ChartBrDepth: React.FC<ChartBrDepthProps> = (props) => {

    let { data, activity, intensity, date, index, isRunningPeak, filters } = props;

    const Axis = new ChartAxis();

    const type = filters.useData;

    const xAxis = filters?.xAxis;

    if (isRunningPeak) {
        if (xAxis == "time") {
            Axis.x = {
                key: "time",
                index: 6,
                label: "Time - min",
                props: {
                    tickFormatter: fancyTimeFormat
                },

            };
        } else if (xAxis == "grade") {
            Axis.x = {
                key: "grade",
                index: 6,
                label: "% Grade",
                props: {
                    tickFormatter: (value) => value + "%",
                },
            };
        } else if (xAxis == "maxSpeed") {
            Axis.x = {
                key: "speed",
                index: 6,
                label: "Speed - KPH (Max Test)",
                props: {
                },
            };
        } else {
            Axis.x = {
                key: "submaxSpeed_" + slug(index),
                index: 6,
                label: "Speed - KPH (Submax Test)",
                props: {
                    // domain: ["dataMin", "dataMax"],
                    // type: "number",
                },
            };
        }

    } else {
        Axis.setXByActivity(activity, intensity);
    }

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    if (isRunningPeak && xAxis == "grade") {
        //Axis.x.props = { type: "category", interval: 0 };
    }

    if (type == "rate") {
        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: "rf_" + slug(date),
            label: date,
            axisLabel: "Rate - brpm",
            props: {
                domain: [0, 80]
            }
        });

        if (date !== index) {

            Axis.y.push({
                axis: false,
                line: true,
                pos: "left",
                index: 1,
                key: "rf_" + slug(index),
                label: index,
                props: {
                    strokeDasharray: "6 4",
                    connectNulls: true,
                }
            });

        }

    }

    if (type == "depth") {
        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: "tvl_" + slug(date),
            label: date,
            axisLabel: "Depth (Tidal Volume) - L",
            props: {
                domain: [0, 6]
            }
        });

        if (date !== index) {

            Axis.y.push({
                axis: false,
                line: true,
                pos: "left",
                index: 1,
                key: "tvl_" + slug(index),
                label: index,
                props: {
                    strokeDasharray: "6 4",
                    connectNulls: true,
                }
            });

        }

    }

    useEffect(() => {
        props.getChartType(type)
    }, [type])

    return (

        <Chart
            key={Axis.x.key}
        >

            <LineChart
                {...ChartProps.LineChart()}
                data={data}
            >

                {Axis.render()}

            </LineChart>

        </Chart>

    )

}

interface ChartBrDepthProps {
    [key: string]: any,
}

export default ChartBrDepth;