import React from 'react';
import { ComposedChart, Scatter, Cell, Bar, LabelList } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { loop, titleCase } from '@dex/bubl-helpers';
import { fancyTimeFormat } from '../../functions/sharedChart';
import { getMinutesTime } from '../../functions/sharedTests';

const ChartCoachingWeeklyVolume: React.FC<ChartCoachingWeeklyVolumeProps> = (props) => {

    let { data, type } = props;

    let dataArray = Object.keys(data).map(key => ({ key: titleCase(key), ...data[key] }));

    let filteredArray = dataArray.filter(item => item.key !== 'Bricks' && item.key !== 'TestRace' && item.key !== 'Other');

    filteredArray = filteredArray.map(item => {
        let newItem = {
            ...item,
            timeActual: getMinutesTime(item.timeActual),
            timeTarget: getMinutesTime(item.timeTarget),
            distanceTarget: parseFloat(item.distanceTarget) || null,
            distanceActual: parseFloat(item.distanceActual) || null,
        };

        if (item.key === 'Swim') {
            newItem = {
                ...newItem,
                distanceTarget: item.distanceTarget / 1000,
                distanceActual: item.distanceActual / 1000,
            };
        }

        return newItem;
    });

    const Axis = new ChartAxis();

    Axis.x.label = "Activity";
    Axis.x.format = undefined;
    Axis.x.key = "key";
    Axis.x.props = {
        interval: 0,
        type: "category",
        ticks: filteredArray.map(item => item.key),

    }

    if (type == "hrs") {
        Axis.y.push({
            axis: true,
            // bar: true,
            pos: "left",
            index: 6,
            key: "timeActual",
            axisLabel: "Hours",
            label: "Actual",
            props: {
                tickFormatter: fancyTimeFormat
            }
        });
        Axis.y.push({
            // axis: false,
            // bar: true,
            pos: "left",
            index: 6,
            key: "timeTarget",
            axisLabel: "Hours",
            label: "Target",
            props: {
                tickFormatter: fancyTimeFormat
            }
        });
    } else {
        Axis.y.push({
            axis: true,
            // bar: true,
            pos: "left",
            index: 6,
            key: "distanceActual",
            axisLabel: "Distance [km]",
            label: "Actual",
            props: {
                domain: [0, "dataMax"],
            }
        });
        Axis.y.push({
            // axis: false,
            // bar: true,
            pos: "left",
            index: 6,
            key: "distanceTarget",
            label: "Target",
            props: {
                domain: [0, "dataMax"],
            }
        });
    };


    return (

        <Chart key={Axis.x?.key}>

            <ComposedChart
                {...ChartProps.BarChart()}
                data={filteredArray}
            >

                {Axis.render()}

                <Bar
                    dataKey={Axis.y[0].key || ""}
                    yAxisId={"left-axis"}
                    barSize={60}
                    fill='#ffffff'
                >
                    {filteredArray.map((item, index) => (
                        <React.Fragment key={index}>
                            <LabelList position="top" fill={ChartProps.indexColor(6)} formatter={Axis.y[0].props.tickFormatter} />
                            <Cell key={`cell-${index}`} fill={ChartProps.activityColor(item.key)} />
                        </React.Fragment>
                    ))}
                </Bar>


                <Scatter
                    dataKey={Axis.y[1].key || ""}
                    yAxisId={"left-axis"}
                    fill={ChartProps.indexColor(2)}
                >
                    {filteredArray.map((item, index) => (
                        <React.Fragment key={index}>
                            <Cell key={`cell-${index}`} fill={ChartProps.indexColor(2)} />
                        </React.Fragment>
                    ))}
                </Scatter>

            </ComposedChart>

        </Chart>
    )

}

interface ChartCoachingWeeklyVolumeProps {
    [key: string]: any,
}

export default ChartCoachingWeeklyVolume;