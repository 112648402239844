import React from 'react';
import { ComposedChart, Scatter, Cell, LabelList, Bar } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { loop } from '@dex/bubl-helpers';
import { fancyTimeFormat, nearestMaxAuto, nearestMaxHour, nearestMaxHundered, nearestMaxTen } from '../../functions/sharedChart';

const ChartCoachingVolumeIntensity: React.FC<ChartCoachingVolumeIntensityProps> = (props) => {

    let { filters, data } = props;

    const getKey = (key) => {

        if (!key) return null;

        const items = { "Hours Above Zone 2": "intensityTotalTime", "TSS Score": "tssScore", "Training Stimulus": "totalStimulusAverage" };

        return items[key];

    };

    const duration = filters?.trackingVolume;
    const metric = filters?.trackingIntensity || [];

    const Axis = new ChartAxis();

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    Axis.x.label = "Week";
    Axis.x.format = undefined;
    Axis.x.key = "date";

    Axis.x.props = {
        interval: 0,
        angle: -45,
        textAnchor: "end",
    }

    if (duration == "Hours") {

        Axis.y.push({
            axis: true,
            // bar: true,
            pos: "left",
            index: 0,
            key: "volumeTotalTime",
            axisLabel: duration,
            label: "Actual",
            props: {
                yAxisId: duration,
                domain: [0, nearestMaxHour],
                tickFormatter: fancyTimeFormat
            }
        });

        Axis.y.push({
            // axis: true,
            // line: true,
            pos: "left",
            index: 3,
            key: "volumeTotalTimeTarget",
            axisLabel: duration,
            label: "Target",
            props: {
                yAxisId: duration,
                domain: [0, nearestMaxHour],
                tickFormatter: fancyTimeFormat
            }
        });

    } else if (duration == "KM") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 1,
            key: "volumeTotalDistance",
            axisLabel: duration,
            label: duration,
            props: {
                yAxisId: duration,
                domain: [0, nearestMaxHundered],
            }
        });
    }

    loop(metric, (_metric, i) => {

        Axis.y.push({
            axis: true,
            line: true,
            pos: "right",
            index: 0,
            key: getKey(_metric),
            axisLabel: _metric,
            label: _metric,
            props: {
                yAxisId: _metric,
                domain: _metric == "Hours Above Zone 2" ? [0, nearestMaxHour] : [0, nearestMaxAuto],
                tickFormatter: _metric == "Hours Above Zone 2" ? fancyTimeFormat : undefined,
                stroke: ChartProps.trackingColor(_metric),
                label: { fill: ChartProps.trackingColor(_metric) }
            },
        });
    })

    // Axis.legend = false;

    return (

        <Chart key={Axis.x?.key}>

            <ComposedChart
                {...ChartProps.BarChart()}
                data={data}
            >

                {duration == "Hours" &&
                    <>
                        <Bar
                            dataKey={Axis.y[0].key || ""}
                            yAxisId={duration}
                            barSize={40}
                            fill={ChartProps.indexColor(0)}
                        >
                            {data?.map((item, index) => (
                                <>
                                    <LabelList position="top" fill={ChartProps.indexColor(0)} formatter={Axis.y[0].props.tickFormatter} />
                                    <Cell key={`cell-${index}`} fill={ChartProps.indexColor(0)} />
                                </>
                            ))}
                        </Bar>


                        <Scatter
                            dataKey={'volumeTotalTimeTarget'}
                            yAxisId={duration}
                            fill={ChartProps.indexColor(2)}
                        >
                            {data?.map((item, index) => (
                                <>
                                    <Cell key={`cell-${index}`} fill={ChartProps.indexColor(2)} />
                                </>
                            ))}
                        </Scatter>

                    </>
                }

                {Axis.render()}

            </ComposedChart>

        </Chart>

    )

}

interface ChartCoachingVolumeIntensityProps {
    [key: string]: any,
}

export default ChartCoachingVolumeIntensity;