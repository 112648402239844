import React from 'react';
import { LineChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';

const ChartSprintHr: React.FC<ChartSprintHrProps> = (props) => {

    let { data, activity, intensity } = props;

    const Axis = new ChartAxis();

    Axis.setXByActivity("time", intensity);

    Axis.x.props = {
        ...Axis.x.props,
        ...props.xAxis,
        interval: 0,
        tickFormatter: undefined
    };

    Axis.x.label = "Sprint Time - Sec";
    Axis.y.push({
        axis: true,
        line: true,
        pos: "left",
        index: 0,
        key: "hr",
        label: "HR",
        axisLabel: 'HR - bpm',
        props: {
            domain: [50, 250],
        }
    });

    return (

        <Chart key={Axis.x?.key}>

            <LineChart
                {...ChartProps.LineChart()}
                data={data}
            >

                {Axis.render()}

            </LineChart>

        </Chart>


    )

}

interface ChartSprintHrProps {
    [key: string]: any,
}

export default ChartSprintHr;