import { Col, Line, Row, Space, useMountEffect } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';

import styles from "./TestSessionsReportSummary.module.scss";
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import SummaryRating from '../../../elements/RatingScale/SummaryRating';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';
import { fixedNum, get, lowerCase } from '@dex/bubl-helpers';
import { getPaceFromKph } from '../../../functions/sharedTests';

const TestSessionsReportSummary: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const isSprintReport = ["sprint-running", "sprint-cycling"].includes(data.reportType);

    let ratings = [
        {
            label: "Health",
            icon: require("../../../assets/images/LabReport/icon-athlete.png"),
            rating: data.summary?.ratings?.health,
        },
        {
            label: "Body Comp",
            icon: require("../../../assets/images/LabReport/icon-bio.png"),
            rating: data.summary?.ratings?.bodyComp,
        },
        isSprintReport ? null : {
            label: "Fitness",
            icon: require("../../../assets/images/LabReport/icon-fitness.png"),
            rating: data.summary?.ratings?.fitness,
        },
        isSprintReport ? null : {
            label: "Circulation",
            icon: require("../../../assets/images/LabReport/icon-circulation.png"),
            rating: data.summary?.ratings?.circulation,
        },
        isSprintReport ? null : {
            label: "Breathing",
            icon: require("../../../assets/images/LabReport/icon-breathing.png"),
            rating: data.summary?.ratings?.breathing,
        },
        isSprintReport ? null : {
            label: "Energy Metabolism",
            icon: require("../../../assets/images/LabReport/icon-energy.png"),
            rating: data.summary?.ratings?.energy,
        },
        {
            label: "Muscle Fitness",
            icon: require("../../../assets/images/LabReport/icon-muscle.png"),
            rating: data.summary?.ratings?.muscle,
        },
        {
            label: data.test.activity === "Running" ? "Speed" : "Power",
            icon: data.test.activity === "Running"
                ? require("../../../assets/images/LabReport/icon-running.png")
                : require("../../../assets/images/LabReport/icon-cycling.png"),
            rating:
                data.test.activity === "Running"
                    ? data.summary?.ratings?.speed
                    : data.summary?.ratings?.power,
        },
    ].filter(Boolean);

    const calculatePowerPerWeight = (power: number, weight: number) => {
        if (power == null || weight == null) return;
        const output = power / weight;
        return fixedNum(output, 1);
    };

    const activity = data.test.activity;
    const gender = data.athlete.gender;
    // const test = data.test;

    // const unit = activity === "Running" ? "kph" : "W";
    // const subUnit = activity === "Running" ? "min/km" : "W/kg";

    // const vo2max = get(test, `vo2.relativeMax`);
    // const hr = get(test, `hr.max`);

    // const fatmaxBurn = test.energy?.fatmaxLoad;
    // const fatmaxBurnValue = fatmaxBurn + " " + unit;
    // const fatmaxBurnSub = activity === "Running" ? getPaceFromKph(fatmaxBurn) : calculatePowerPerWeight(fatmaxBurn, data.bio.weight);
    // const fatmaxBurnSubValue = fatmaxBurnSub + " " + subUnit;

    // const maxAerobic = test.lactate?.measures?.max;
    // const maxAerobicValue = maxAerobic + " " + unit;
    // const maxAerobicSub = activity === "Running" ? getPaceFromKph(maxAerobic) : calculatePowerPerWeight(maxAerobic, data.bio.weight);
    // const maxAerobicSubValue = maxAerobicSub + " " + subUnit;

    // const threshold = get(test, `lactate.measures.1stSteepRise`);
    // const thresholdValue = threshold + " " + unit;
    // const thresholdSub = activity === "Running" ? getPaceFromKph(threshold) : calculatePowerPerWeight(threshold, data.bio.weight);
    // const thresholdSubValue = thresholdSub + " " + subUnit;

    // const tempoTransition = test.lactate?.measures?.base;
    // const tempoTransitionValue = tempoTransition + " " + unit;
    // const tempoTransitionSub = activity === "Running" ? getPaceFromKph(tempoTransition) : calculatePowerPerWeight(tempoTransition, data.bio.weight);
    // const tempoTransitionSubValue = tempoTransitionSub + " " + subUnit;

    const activitySummary = data.summary[lowerCase(activity)];

    return useMemo(() => (

        <>

            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            <Row gutter={16} edge>

                <Col col={{ xs: 24 }}>

                    <Row gutter={16} edge>

                        {ratings.map((item, index) => (
                            <React.Fragment key={index}>

                                <Col col={{ xs: 12, sm: 8, md: 6 }} justify='end' >

                                    <p className={styles.label}>{item?.label}</p>

                                    <Space height={5} />

                                    <div className={styles.iconContainer}>
                                        <img src={item?.icon} alt={"icon"} className={styles.icon} />
                                    </div>

                                    <Space height={5} />

                                    <SummaryRating value={item?.rating} />

                                </Col>

                            </React.Fragment>
                        ))}

                    </Row>

                    <Space height={10} />

                </Col>

                <Col col={{ xs: 8 }} align='end'>

                    <Space height={50} />

                    <SummaryDataLabel
                        label={activitySummary?.vo2Source == "Peak" ? "VO2 Peak" : "VO2max"}
                        value={activitySummary?.vo2}
                        subValue="ml/kg*min"
                    />

                    <Space height={50} />

                    <SummaryDataLabel
                        label="Max Aerobic"
                        value={activitySummary?.maxAerobicSpeed + (activity == "Running" ? " kph" : " W")}
                        subValue={activitySummary?.maxAerobicPace}
                    />

                    <Space height={50} />

                    <SummaryDataLabel
                        label="Tempo Onset"
                        value={activitySummary?.tempoOnsetSpeed + (activity == "Running" ? " kph" : " W")}
                        subValue={activitySummary?.tempoOnsetPace}
                    />

                </Col>

                <Col col={{ xs: 8 }} align='center'>

                    <img
                        src={require(`../../../assets/images/LabReport/${gender}-${activity}.png`)}
                        alt={"icon"}
                        className={styles.image}
                    />

                </Col>

                <Col col={{ xs: 8 }} align='start'>

                    <Space height={50} />

                    <SummaryDataLabel
                        label={activitySummary?.hrSource == "Peak" ? "HR Peak" : "HRmax"}
                        value={activitySummary?.hr}
                        subValue="bpm"
                    />

                    <Space height={50} />

                    <SummaryDataLabel
                        label="Threshold/FTP"
                        value={activitySummary?.thresholdSpeed + (activity == "Running" ? " kph" : " W")}
                        subValue={activitySummary?.thresholdPace}
                    />

                    <Space height={50} />

                    <SummaryDataLabel
                        label="Fat Max Burn"
                        value={activitySummary?.fatMaxBurnSpeed + (activity == "Running" ? " kph" : " W")}
                        subValue={activitySummary?.fatMaxBurnPace}
                    />

                </Col>

            </Row>

        </>

    ), [data]);

}

const SummaryDataLabel: React.FC<any> = (props: any) => {

    const { value, subValue, label } = props;

    return (
        <div className={styles.labelContainer}>
            <p className={styles.heading}>
                {label}
            </p>

            <p className={styles.value}>
                {value}
            </p>

            <p className={styles.subValue}>
                {subValue}
            </p>

        </div>
    )
}

export default TestSessionsReportSummary;