import React from 'react';
import { ComposedChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { loop, titleCase } from '@dex/bubl-helpers';
import { get } from 'http';
import { fancyTimeFormat, nearestMaxHour, nearestMaxHundered, nearestMaxTen, nearestMaxThousand, nearestMinHundered, nearestMinOne, nearestMinTen, nearestMinThousand } from '../../functions/sharedChart';

const ChartCoachingWorkoutRunEndurance: React.FC<ChartCoachingWorkoutRunEnduranceProps> = (props) => {

    let { filters, data } = props;

    const duration = filters?.runEnduranceDuration;
    const metric = filters?.runIntervalMetric || [];

    const getKey = (key: string) => {

        if (!key) return;

        const item = {
            "Avg Pace": "avgPace",
            "Hrs Zone 2+": "raceTime",
            "Avg HR": "hrAvg",
            "Peak HR": "hrPeak",
            "Climbing M": "elevation",
            "Avg KPH": "avgSpeed"
        }

        return item[key];

    };

    const getAxisId = (key: string) => {

        if (!key) return;

        const item = {
            "Avg Pace": "Pace [min/km]",
            "Hrs Zone 2+": "Hrs Zone 2+ [hh:mm]",
            "Avg HR": "HR [bpm]",
            "Peak HR": "HR [bpm]",
            "Climbing M": "Climbing [m]",
            "Avg KPH": "Speed [kph]"
        }

        return item[key];

    };

    const getDomain = (key: string) => {

        if (!key) return;

        const item = {
            "Avg Pace": [3, nearestMaxHour],
            "Hrs Zone 2+": [0, 360],
            "Avg HR": [80, nearestMaxHundered],
            "Peak HR": [80, nearestMaxHundered],
            "Climbing M": [nearestMinThousand, nearestMaxThousand],
            "Avg KPH": [nearestMinTen, nearestMaxTen],
        }

        return item[key];

    };

    const Axis = new ChartAxis();

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    Axis.x.label = "Week";
    Axis.x.format = undefined;
    Axis.x.key = "date";
    Axis.x.props = {
        interval: 0,
        angle: -45,
        textAnchor: "end",
    }

    if (duration == "Hours") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 0,
            key: "duration",
            axisLabel: duration,
            label: duration,
            props: {
                yAxisId: duration,
                domain: [0, nearestMaxHour],
                tickFormatter: fancyTimeFormat
            }
        });

    } else if (duration == "KM") {
        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 1,
            key: "distance",
            axisLabel: duration,
            label: duration,
            props: {
                yAxisId: duration,
                domain: [0, nearestMaxTen],
            }
        });

    }

    loop(metric, (_metric, i) => {
        Axis.y.push({
            axis: true,
            line: true,
            pos: "right",
            index: 0,
            key: getKey(_metric),
            axisLabel: getAxisId(_metric),
            label: _metric,
            props: {
                yAxisId: getAxisId(_metric),
                domain: getDomain(_metric) || [0, "dataMax"],
                tickFormatter: _metric == "Avg Pace" || _metric == "Hrs Zone 2+" ? fancyTimeFormat : undefined,
                strokeDasharray: _metric.includes("Peak") ? "6 4" : "",
                stroke: ChartProps.metricColor(_metric),
                label: { fill: ChartProps.metricColor(_metric) }
            },
        });
    })

    Axis.legend = false;

    return (

        <Chart key={Axis.x?.key}>

            <ComposedChart
                {...ChartProps.BarChart()}
                data={data}
            >

                {Axis.render()}

            </ComposedChart>

        </Chart>

    )

}

interface ChartCoachingWorkoutRunEnduranceProps {
    [key: string]: any,
}

export default ChartCoachingWorkoutRunEndurance;