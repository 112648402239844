import React from 'react';
import { ComposedChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { loop, titleCase } from '@dex/bubl-helpers';
import { fancyTimeFormat, nearestMaxHour, nearestMaxTen, nearestMinTen } from '../../functions/sharedChart';

const ChartCoachingHeartrate: React.FC<ChartCoachingHeartrateProps> = (props) => {

    let { filters, data } = props;

    const type = filters?.trackingHeartrateZone2;
    const metric = filters?.trackingHeartratePeakHR || [];

    const getKey = (key) => {

        if (!key) return null;

        const items = { "All": "hrPeakAll", "Bike": "hrPeakBikeTime", "Run": "hrPeakRunTime" };

        return items[key];

    };

    const Axis = new ChartAxis();

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    Axis.x.label = "Week";
    Axis.x.format = undefined;
    Axis.x.key = "date";

    Axis.x.props = {
        interval: 0,
        angle: -45,
        textAnchor: "end",
    }

    if (type == "All") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 5,
            key: "intensityTotalHrTime",
            axisLabel: "Hours",
            label: type,
            props: {
                yAxisId: type,
                domain: [0, nearestMaxHour],
                tickFormatter: fancyTimeFormat
            }
        });

    } else if (type == "Bike") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 0,
            key: "hrBikeTime",
            axisLabel: "Hours",
            label: type,
            props: {
                yAxisId: type,
                domain: [0, nearestMaxHour],
                tickFormatter: fancyTimeFormat
            }
        });
    } else if (type == "Run") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 4,
            key: "hrRunTime",
            axisLabel: "Hours",
            label: type,
            props: {
                yAxisId: type,
                domain: [0, nearestMaxHour],
                tickFormatter: fancyTimeFormat
            }
        });
    }

    loop(metric, (_metric, i) => {

        Axis.y.push({
            axis: true,
            line: true,
            pos: "right",
            index: 6,
            key: getKey(_metric),
            axisLabel: "HR - BPM",
            label: _metric,
            props: {
                yAxisId: "right",
                domain: [nearestMinTen, nearestMaxTen],
                stroke: ChartProps.trackingColor(_metric),
            },
        });
    })

    Axis.legend = false;

    return (

        <Chart key={Axis.x?.key}>

            <ComposedChart
                {...ChartProps.BarChart()}
                data={data}
            >

                {Axis.render()}

            </ComposedChart>

        </Chart>

    )

}

interface ChartCoachingHeartrateProps {
    [key: string]: any,
}

export default ChartCoachingHeartrate;