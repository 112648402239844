import React from 'react';
import { ComposedChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { loop } from '@dex/bubl-helpers';
import { nearestMaxTen } from '../../functions/sharedChart';
import { fill } from 'pdf-lib';

const ChartCoachingWorkout: React.FC<ChartCoachingWorkoutProps> = (props) => {

    let { filters, data } = props;

    const items = { "Swim": "workoutsSwim", "Bike": "workoutsBike", "Run": "workoutsRun", "Brick": "workoutsBrick", "Gym": "workoutsGym", "Other": "workoutsOther", "Event/Test": "workoutsEvent" };

    const metric = filters?.trackingWorkouts || [];

    const Axis = new ChartAxis();

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    Axis.x.label = "Week";
    Axis.x.format = undefined;
    Axis.x.key = "date";

    Axis.x.props = {
        interval: 0,
        angle: -45,
        textAnchor: "end",
    }

    Axis.y.push({
        axis: true,
        circle: false,
        pos: "left",
        index: 6,
        key: "workoutsAll",
        axisLabel: "# Of Workouts",
        label: "Total",
        props: {
            domain: [0, nearestMaxTen],
            stroke: ChartProps.indexColor(6),
        },
    });

    let i = 0;

    loop(items, (key, label) => {

        if (metric.includes(label)) {

            Axis.y.push({
                axis: false,
                bar: true,
                pos: "left",
                index: 6,
                key: key,
                axisLabel: "Number Of Workouts",
                label: label,
                props: {
                    stackId: "a",
                    label: true,
                    stroke: ChartProps.trackingColor(label),
                    fill: ChartProps.trackingColor(label),
                },
            });

            i++;

        }

    })

    Axis.legend = false;

    return (

        <Chart key={Axis.x?.key}>

            <ComposedChart
                {...ChartProps.BarChart()}
                data={data}
            >

                {Axis.render()}

            </ComposedChart>

        </Chart>

    )

}

interface ChartCoachingWorkoutProps {
    [key: string]: any,
}

export default ChartCoachingWorkout;