import React from 'react';
import { BarChart, LineChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';

const ChartBloodGlucose: React.FC<ChartBloodGlucoseProps> = (props) => {

    let { data, intensity } = props;

    const Axis = new ChartAxis();

    Axis.setXByActivity("history", intensity);

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    //set y axis
    Axis.y.push({
        axis: true,
        bar: true,
        pos: "left",
        index: 0,
        key: "restingBloodGlucose",
        axisLabel: "Blood Glucose - mmol/l",
        label: "Blood Glucose",
    });

    return (

        <Chart key={Axis.x?.key}>

            <BarChart
                {...ChartProps.BarChart()}
                data={data}
            >

                {Axis.render()}

            </BarChart>

        </Chart>


    )

}

interface ChartBloodGlucoseProps {
    [key: string]: any,
}

export default ChartBloodGlucose;